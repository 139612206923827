import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import useImages from '../hooks/useImages';
import { CSS } from '../styles/css';
import useHeader from './useHeader';

const Header = memo(() => {
   const { BOX, HEADER } = CSS
   const self = useHeader()
   const images = useImages()

   return (
      <>
      <HEADER>
         <div types={'wrap'}>
            <h1>
               {self.media ? images({ types: 'header.logo.small'}) : images({ types: 'header.logo.large' })}
            </h1>
            {!self.media && <nav>
               <ul>
               {self.nav.items && self.nav.items.map((l, ldex) => 
                  <li key={ldex}>
                     <NavLink to={l.pathname === '/index' ? '/' : l.pathname}
                        activeClassName={'active'}
                        exact={l.pathname === '/index'}
                     >
                        {l.name}
                     </NavLink>
                  </li>
               )}
               </ul>
            </nav>}
            {self.media && <>
               <button onClick={self.nav.onClick}>
                  {images({ types: 'header.nav.button', color: '#226ab2'})}
               </button>
            </>}
         </div>
         {self.media && 
            <BOX PhoneNavArea
               Expand={self.nav.expand}
            >
               <nav>
                  <ul>
                     {self.nav.items && self.nav.items.map((l, ldex) =>
                        <li key={ldex}>
                           <NavLink to={l.pathname === '/index' ? '/' : l.pathname}
                              activeClassName={'active'}
                              exact={l.pathname === '/index'}
                           >
                              {l.name}
                           </NavLink>
                        </li>
                     )}
                  </ul>
               </nav>
            </BOX>
         }
      </HEADER>
      </>
   );
});

export default Header;