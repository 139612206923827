import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { CSS } from '../styles/css';
import useFooter from './useFooter';

const Footer = memo(() => {
   const { FOOTER } = CSS
   const ft = useFooter()

   return (
      <FOOTER>
         <div types={'h'}>
            {ft.media ? <>
               <h1>{ft.logo.img !== null && ft.logo.img}</h1>
               <nav>
                  <ul>
                  {ft.nav.items && ft.nav.items.map((i, idex) => 
                     <li key={idex}>
                        <NavLink to={i.pathname === '/index' ? '/' : i.pathname}
                           activeClassName={'active'}
                           exact={i.pathname === '/index'}
                        >
                           {i.name}
                        </NavLink>
                     </li>
                  )}
                  </ul>
               </nav>
            </> : <>
            <div types={'hh'}>
               <h1>{ft.logo.img !== null && ft.logo.img}</h1>
               <div>
                  <p>{ft.etc.address}</p>
                  <p>{ft.etc.businessNumber}</p>
                  <p>{ft.etc.call} | {ft.etc.fax} | {ft.etc.owner}</p>
               </div>
            </div>
            <nav types={'nn'}>
               <ul>
                  {ft.nav.items && ft.nav.items.map((i, idex) =>
                     <li key={idex}>
                        <NavLink to={i.pathname === '/index' ? '/' : i.pathname}
                           activeClassName={'active'}
                           exact={i.pathname === '/index'}
                        >
                           {i.name}
                        </NavLink>
                     </li>
                  )}
               </ul>
            </nav>
            </>}
         </div>
         {!ft.media && <>
            <div types={'cr'}>
               <p>{ft.etc.copyright}</p>
               <p types={'email'}>{ft.etc.emailImg}&nbsp;&nbsp;{ft.etc.email}</p>
            </div>
         </>}
         {ft.media && 
            <div types={'b'}>
               <p>{ft.etc.address}</p>
               <p>{ft.etc.businessNumber}</p>
               <p>{ft.etc.call} | {ft.etc.fax} | {ft.etc.owner}</p>
               <div>
                  <p>{ft.etc.copyright}</p>
                  <p types={'email'}>{ft.etc.emailImg}&nbsp;&nbsp;{ft.etc.email}</p>
               </div>
            </div>
         }
         <button onClick={ft.topClick}>
            top
         </button>
      </FOOTER>
   );
});

export default Footer;