import styled, { css } from "styled-components";

export const CSS = {
   CONTAINER: styled.div`
      display: flex; flex-direction: column;
      width: 100vw;
      min-width: 300px;
   `,
   BOX: styled.div`
      ${props => props.PhoneNavArea && css`
         display: flex; justify-content: center;
         transition: all 0.5s;
         ${props.Expand ? css`
            padding: 10px;
         ` : css`
            height: 0;
            padding: 0;
            overflow: hidden;
         `}
         &>nav {
            max-width: 350px;
            &>ul {
               display: flex;
               &>li {
                  height: 2.3rem;
                  margin: 0px 10px;
                  &>a {
                     font-family: 'Poppins', sans-serif;
                     font-size: 1.5rem;
                     font-weight: 500;
                     padding: 0 5px;
                     color: rgb(28 33 93);
                     &.active {
                        color: rgb(28 33 93 / 0.5);
                     }
                  }
               }
            }
         }
      `}
   `,
   HEADER: styled.header`
      z-index: 9999;
      &>[types="wrap"] {
         display: flex; justify-content: space-between; align-items: center;
         height: 90px;
         max-width: 1024px; min-width: 300px;
         padding: 0rem 2rem; margin: auto;
         @media screen and (max-width: 1023px)  {
            height: 50px;
            padding: 0 1rem;
         }
         &>h1 {
            display: flex; align-items: center;
         }
         &>nav {
            height: 3.0rem;
            &>ul {
               display: flex;
               &>li {
                  height: 2.3rem;
                  margin: 0px 0px 0px 10px;
                  &>a {
                     font-family: 'Poppins', sans-serif;
                     font-size: 1.6rem;
                     font-weight: 500;
                     padding: 0 10px;
                     color: rgb(28 33 93);
                     &.active {
                        color: rgb(28 33 93 / 0.5);
                     }
                  }
               }
            }
         }
      }
   `,
   MAIN: styled.main`
   
   `,
   FOOTER: styled.footer`
      background-color: #4d4d4d;
      &>[types="h"] {
         display: flex; justify-content: space-between;
         padding: 2rem;
         h1 {
            display: flex;
            width: 130px; height: 35px;
         }
         nav {
            ul {
               display: flex;
               height: 100%;
               li {
                  color: rgb(135 135 135);
                  a {
                     padding: 5px;
                  }
               }
            }
         }
      }
      &>[types="b"] {
         color: rgb(135 135 135);
         padding: 2rem;
         &>div {
            display: flex;
            padding: 1rem 0;
            * + * {
               padding-left: 20px;
            }
            svg {
               width: 18px;
            }
         }
      }
      &>button {
         width: 100%; height: 36px;
         color: rgb(135 135 135);
         background-color: #2f2f2f;
      }
      [types="hh"] {
         display: flex;
         &>div {
            padding-left: 3rem;
            color: rgb(135 135 135);
            font-size: 14px;
         }
      }
      [types="nn"] {
         li {
            padding: 0 10px;
         }
         ul>li>a {
            font-size: 14px;
         }
      }
      [types="cr"] {
         display: flex; justify-content: space-around;
         color: rgb(135 135 135);
         font-size: 14px;
         padding: 5rem 0 3rem;
      }
      [types="email"] {
         display: flex; align-items: center;
      }
   `
}