import { call, put, takeLatest } from "@redux-saga/core/effects";
import { createAction, handleActions } from "redux-actions";
import createRequestTypes from "../library/createRequestTypes";

const [
   APP_NAV_ITEMS, APP_NAV_ITEMS_SUCCESS, APP_NAV_ITEMS_FAILURE,
] = createRequestTypes('APP_NAV_ITEMS')

export const onApp = {
   navItems: createAction(APP_NAV_ITEMS, items => items)
}

export function* appSaga() {
   yield takeLatest(APP_NAV_ITEMS, saga.navItems(APP_NAV_ITEMS, api.navItems))
}

const initial = {
   navItems: null,
}

const app = handleActions({
   [APP_NAV_ITEMS_SUCCESS]: (state, { payload }) => ({
      ...state,
      navItems: payload
   }),
   [APP_NAV_ITEMS_FAILURE]: (state, { payload }) => ({
      ...state,
      navItems: null
   }),
}, initial)

export default app

const saga = {
   navItems: function (type, request) {
      const SUCCESS = `${type}_SUCCESS`
      const FAILURE = `${type}_FAILURE`
      return function* (action) {
         try {
            const response = yield call(request, action.payload)
            yield put({
               type: SUCCESS,
               payload: response
            })
         } catch (error) {
            console.log(error, FAILURE)
         }
      }
   },
}
const api = {
   navItems: function (props) {
      return props
   },
}