import React, { memo, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import Footer from './#footer/Footer';
import Header from './#header/Header';
import { CSS } from './styles/css';

const HOME = React.lazy(() => import('../@home/Home'))
const ABOUT = React.lazy(() => import('../@about/About'))
const BUSINESS = React.lazy(() => import('../@business/Business'))
const CONTACT = React.lazy(() => import('../@contact/Contact'))

const App = memo(() => {
   const { CONTAINER, MAIN } = CSS
   return (
      <CONTAINER>
         <Header />
         <MAIN>
            <Switch>
               <Route 
                  path={'/'} exact={true}
                  render={() => (
                     <Suspense fallback={<></>}>
                        <HOME />
                     </Suspense>
                  )}
               />
               <Route 
                  path={'/about'} exact={true}
                  render={() => (
                     <Suspense fallback={<></>}>
                        <ABOUT />
                     </Suspense>
                  )}
               />
               <Route 
                  path={'/business'}
                  render={() => (
                     <Suspense fallback={<></>}>
                        <BUSINESS />
                     </Suspense>
                  )}
               />
               <Route 
                  path={'/contact'} exact={true}
                  render={() => (
                     <Suspense fallback={<></>}>
                        <CONTACT />
                     </Suspense>
                  )}
               />
            </Switch>
         </MAIN>
         <Footer />
      </CONTAINER>
   );
});

export default App;