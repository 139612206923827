import { useCallback, useEffect, useState } from "react"
import useImages from "../hooks/useImages"
import useMedia from "../hooks/useMedia"

export default function useFooter() {
   const media = useMedia('(max-width: 1023px)')
   const images = useImages()
   
   const [ logo, setLogo ] = useState({
      img: images({ types: 'header.logo.large', color: 'rgb(135 135 134)' }),
   })

   const [nav, setNav] = useState({
      items: [
         { name: 'Home', pathname: '/index' },
         { name: 'About', pathname: '/about' },
         { name: 'Business', pathname: '/business' },
         { name: 'Contacts', pathname: '/contact' },
      ],
      expand: false,
      onClick: useCallback((evnet) => {
         setNav(n => ({ ...n, expand: !n.expand }))
      }, []),
      useHook: function (props) {
         const { media } = props
         useEffect(() => {
            if (!media) {
               setNav(n => ({ ...n, expand: false }))
            }
         }, [media])
      }
   })

   const [etc, setEtc] = useState({
      address: '경상남도 창원시 의창구 충혼로 91, 벤처창업관 11호관 2층 3호',
      businessNumber: '사업자등록번호 : 692-88-01091',
      call: '대표번호 : 055-980-3388',
      fax: 'FAX : 055-980-3399',
      owner: '대표자 : 손정휘',
      copyright: 'Copyright ⓒ 2021 KDATALAB',
      email: 'ai@kdatalab.com',
      emailImg: images({ types: 'email' }),
   })

   const topClick = useCallback(() => {
      const root = document.getElementById('root')
      root.scrollTop = 0
   }, [])

   const data = {
      media,
      logo, nav, etc,
      topClick
   }

   useEffect(() => {
      return () => {
         console.log('clean up - footer')
         setLogo(null)
         setNav(null)
         setEtc(null)
      }
   }, [])

   return data
}