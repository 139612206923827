import { useCallback, useEffect, useRef, useState } from "react"
import useMedia from "../hooks/useMedia"

export default function useHeader() {
   const media = useMedia('(max-width: 1023px)')

   const [ ref, setRef ] = useState({
      header: useRef(null)
   })

   const [ nav, setNav ] = useState({
      items: [
         { name: 'HOME', pathname: '/index' },
         { name: 'ABOUT', pathname: '/about' },
         { name: 'Business', pathname: '/business' },
         { name: 'Contacts', pathname: '/contact' },
      ],
      expand: false,
      onClick: useCallback((evnet) => {
         setNav(n => ({ ...n, expand: !n.expand }))
      }, []),
      useHook: function(props) {
         const { media } = props
         useEffect(() => {
            if (!media) {
               setNav(n => ({ ...n, expand: false }))
            }
         }, [media])
      }
   })

   const data = {
      media,
      ref, nav
   }

   nav.useHook(data)

   useEffect(() => {
      return () => {
         setRef(null)
         setNav(null)
      }
   }, [])

   return data
}