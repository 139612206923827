import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'
import app, { appSaga } from './app'

//root reducer
const rootReducer = combineReducers({
   app,
})

//root saga
export function* rootSaga() {
   yield all([
      appSaga()
   ])
}

export default rootReducer